@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');


@layer components {
    .router-body {
        @apply bg-base-200 flex flex-auto flex-col items-center overflow-hidden
    }
}
